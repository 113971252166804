import React, { useMemo } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({
  pageSettings,
  blogSettings,
  title,
  type,
  slug,
  excerpt,
  imageBlock,
  readingTime,
  tags,
  category,
}) => {
  const postCategory = useMemo(() => {
    return {
      articles: 'Article',
      podcasts: 'Podcast',
      guides: 'Guide',
    }[category.title.toLowerCase()]
  }, [category])

  const postCategoryUrl = useMemo(() => {
    return {
      articles: blogSettings.articlesLink,
      podcasts: blogSettings.podcastsLink,
      guides: blogSettings.guidesLink,
    }[category.title.toLowerCase()]
  }, [category, blogSettings])

  return (
    <Link className={cx(styles.post, styles[type])} to={`${pageSettings.path}/${slug?.current}`}>
      <div className={styles.image}>
        <img
          className={styles.imageSrc}
          {...srcSetProps(
            sanityImageUrl(imageBlock),
            [
              [800, 800],
              [480, 480],
            ],
            70,
            1000,
          )}
          alt={imageBlock?.caption}
        />
      </div>
      <div className={styles.top}>
        <span className={styles.type}>{postCategory}</span>
        {readingTime && <span className={styles.time}>{readingTime} min read</span>}
      </div>
      <p className={styles.title}>{title}</p>
      <p className={styles.info}>{excerpt}</p>
    </Link>
  )
}
